import React from 'react'
import wrapWithProvider from './src/wrap-with-provider'

import './src/styles/global.css'
import 'leaflet/dist/leaflet.css'
import { AnalyticsProvider } from './src/utils/analyticsContext'

let routeKey = 0
export const wrapRootElement = ({ element }) => {
  return (
    <AnalyticsProvider routeKey={routeKey}>
      {wrapWithProvider({ element })}
    </AnalyticsProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  routeKey += 1
  if (prevLocation) {
    localStorage.setItem('prevLocation', prevLocation.href)
    const pathArray = prevLocation.pathname.split('/')
    const prevPage = pathArray.length > 2 ? (pathArray[pathArray.length - 2]) : 'Home'
    localStorage.setItem('prevPage', prevPage)
  }
}
