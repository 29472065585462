interface ApiCallerProps {
  url: string
  method: 'GET' | 'DELETE' | 'PATCH' | 'POST' | 'PUT'
  data?: any
}

export const apiCaller = async ({ url, method, data }: ApiCallerProps): Promise<any> => {
  const options = {
    method
  }

  // console.log('PUT Call initiated: ', data)
  // Append body property for post and patch requests
  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    if (url.includes('dboperator')) {
      const subId = process.env.DBO_SUB_ID ?? ''
      Object.assign(options, {
        body: data,
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': subId
        }
      })
    } else {
      Object.assign(options, {
        body: data,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  } else {
    if (url.includes('dboperator')) {
      const subId = process.env.DBO_SUB_ID ?? ''
      Object.assign(options, {
        headers: {
          'Content-Type': 'application/json',
          'Ocp-Apim-Subscription-Key': subId
        }
      })
    }
  }

  return await fetch(url, options)
}
