import React, { createContext, useContext, useEffect, useRef } from 'react'
import { useTracking } from 'react-tracking'
import { createAnalytics } from '../services/api/dbOperator'
import { Analytic } from './types/dataStructures'

const AnalyticsContext = createContext()
export const useAnalytics = () => useContext(AnalyticsContext)

export const AnalyticsProvider = ({ children, routeKey }) => {
  const startTimeRef = useRef(0)
  const dispatchDataRef = useRef({})
  const timeSpentRef = useRef(0)
  let sessionId = ''
  let requesterName = ''
  let requesterEmail = ''
  if (typeof window !== 'undefined') {  
    sessionId = window.sessionStorage.getItem('sessionId')
    requesterName = window.sessionStorage.getItem('name')
    requesterEmail = window.sessionStorage.getItem('email')
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      startTimeRef.current = new Date().getTime()

      const interval = setInterval(() => {
        const currentTime = new Date().getTime()
        timeSpentRef.current = ((currentTime - startTimeRef.current) / 1000)
      }, 1000)

      return async () => {
        clearInterval(interval)
        const endTime = new Date().getTime()
        const duration = (endTime - startTimeRef.current) / 1000
        const trackingData = {
          value: duration.toString(),
          action: 'pageview', // e.g., "click"
          page_name: localStorage.getItem('prevPage'),
          page_url: localStorage.getItem('prevLocation'),
          parentid: '0',
          timestamp: new Date().toISOString()
        }
        await sendDataToAPI(trackingData)
      }
    }
  }, [routeKey])

  const { Track } = useTracking(
    {},
    {
      dispatch: async data => {
        if (typeof window !== 'undefined') {
          data.datetime = new Date()

          if (data.action === 'pageview' && data.page === data.value) {
            localStorage.setItem('currentPageName', data.page)
            localStorage.setItem('prevUrl', window.location.href)
          }
          if (data.action === 'pageview') {
            data.value = timeSpentRef.current
          }
          data.page_url = window.location.href
          data.parentid = 0
          // Save the dispatch data to the ref
          dispatchDataRef.current = data
          if (data.action !== 'pageview') {
            await sendDataToAPI(dispatchDataRef.current)
          }
        }
      }
    }
  )

  const sendDataToAPI = async (data: any): Promise<any> => {
    try {
      const payload = createAnalyticPayload(data)
      console.log('payload')
      console.log(payload)

      const response = await createAnalytics(payload)

      console.log('Analytic response:', response)
    } catch (error) {
      console.error('Error sending data:', error)
    }
  }
  function createAnalyticPayload(trackingData: any): Analytic {
    try {
      const analyticPayload = {
        sessionid: sessionId,
        user_name: requesterName,
        email: requesterEmail,
        page_name: trackingData.page_name,
        page_url: trackingData.page_url,
        event_type: trackingData.action,
        event_value: trackingData.value.toString(),
        parentid: trackingData.parentid.toString()
      }
      if (!analyticPayload.page_name && localStorage.getItem('currentPageName')) {
        analyticPayload.page_name = localStorage.getItem('currentPageName')
      }
      return analyticPayload
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <AnalyticsContext.Provider>
      <Track>{children}</Track>
    </AnalyticsContext.Provider>
  )
}
